import React,{useState,useEffect} from 'react'
import './Homepage/gradientCss.css'
import {Link} from 'react-router-dom' 
import Nav from 'react-bootstrap/Nav';
import logo from '../assets/Zema_Logo_Original.webp'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const Footer = () => {

    const [decoded,setDecoded]=useState(undefined)
    const [showLogin,setShowLogin]=useState(true)

    const navigate = useNavigate();

    const logout=()=>{
        Cookies.remove('accessToken')
        Cookies.remove('adminToken')
         toast.success("Logout Successful")
         navigate('/login');
      }

    useEffect(()=>{
        setDecoded(Cookies.get('accessToken'));
        // console.log(Cookies.get('accessToken'),"footer",decoded)
    },[decoded])

    useEffect(()=>{
        if(decoded === undefined){
          setShowLogin(true)
        }else{
            setShowLogin(false)
        }
        // console.log("show login ",showLogin)
    },[decoded])
    

  return (
    <div  className='sectionOneGradient  row mx-0' style={{ height: '100%',width:'100vw' }}>
        <div className='col-10 container mt-4 mb-1' style={{borderLeft:'1px solid rgba(255,255,255,0.3)'}}>
    <div className='  ' >
    <div className='fw-bolder col-12 d-flex justify-content-lg-start justify-content-center container pt-0' style={{ color: '#FFFFFF', }}>

    <ul 
  style={{ 
    listStyle: 'none', 
    padding: 0, 
    margin: 0 
  }} 
  className='text-lg-start col-12 d-flex justify-content-between align-items-center flex-wrap text-center'
>
  <li className='nav-item col-lg-2 col-sm-6 col-12 text-lg-start text-center d-flex justify-content-center align-items-center'>
    <Link to='/' style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
      <img loading="lazy" src={logo} alt='ZEMA' style={{ height: '80px',width:'80px' }} />
    </Link>
  </li>
  
  {showLogin ? (
    <>
      <li className='nav-item col-lg-2 col-sm-6 col-12 text-lg-center text-center d-flex justify-content-center align-items-center' >
        <span style={{ visibility: 'hidden' }}>.</span>
      </li>
      
      <li className='nav-item col-lg-2 col-sm-6 col-12 text-lg-center text-center d-flex justify-content-center align-items-center' style={{ borderLeft: '1px solid rgba(255,255,255,0.3)', borderRight: '1px solid rgba(255,255,255,0.3)' }}>
        <h4 className='my-1 px-0'>
          <Link to='/login' style={{ textDecoration: 'none', color: 'white' }}>
            Login
          </Link>
        </h4>
      </li>
      
      <li className='nav-item col-lg-2 col-sm-6 col-12 text-lg-center text-center d-flex justify-content-center align-items-center' style={{ borderRight: '1px solid rgba(255,255,255,0.3)' }}>
        <h4 className='my-1 px-0'>
          <Link to='/signup' style={{ textDecoration: 'none', color: 'white' }}>
            Sign Up
          </Link>
        </h4>
      </li>
    </>
  ) : (
    <>
      <li className='nav-item col-lg-2 col-sm-6 col-12 text-lg-center text-center d-flex justify-content-center align-items-center'>
        <span style={{ visibility: 'hidden' }}>.</span>
      </li>
      
      <li className='nav-item col-lg-2 col-sm-6 col-12 text-lg-center text-center d-flex justify-content-center align-items-center'>
        <span style={{ visibility: 'hidden' }}>.</span>
      </li>
      
      <li className='nav-item col-lg-2 col-sm-6 col-12 text-lg-center text-center d-flex justify-content-center align-items-center' style={{ borderLeft: '1px solid rgba(255,255,255,0.3)', borderRight: '1px solid rgba(255,255,255,0.3)' }}>
        <h4 className='my-1 px-0'>
          <Link to='/login' style={{ textDecoration: 'none', color: 'white' }}>
            <span onClick={() => logout()}>Logout</span>
          </Link>
        </h4>
      </li>
    </>
  )}

  <li className='nav-item col-lg-2 col-sm-6 col-12 text-lg-center text-center d-flex justify-content-center align-items-center' style={{ borderRight: '1px solid rgba(255,255,255,0.3)' }}>
    <h4 className='my-1 px-0'>
      <Link to='/blog' style={{ textDecoration: 'none', color: 'white' }}>
        Blogs
      </Link>
    </h4>
  </li>

  <li className='nav-item col-lg-2 col-sm-6 col-12 text-lg-center text-center d-flex justify-content-center align-items-center' style={{ borderRight: '1px solid rgba(255,255,255,0.3)' }}>
    <h4 className='my-1 px-0'>
      <Link to='/guide' style={{ textDecoration: 'none', color: 'white' }}>
        Guide
      </Link>
    </h4>
  </li>
</ul>

  </div>
        
    </div>
    <div className=' col-xl-12 col-12 container  d-flex justify-content-lg-between justify-content-center flex-wrap' >
    <div  className='fw-bolder  px-2  pt-3' style={{ color: '#FFFFFF' }}>
            <ul style={{ listStyle:'none' }} className='text-center text-lg-start px-0'>
               <h3 className='my-2' >@ 2025 Zema | All Rights Reserved</h3>
                 
            </ul>
        </div>
        <div  className='fw-bolder  px-2 pt-3' style={{ color: '#FFFFFF' }}>
            <ul style={{ listStyle:'none' }} className='text-center text-lg-end px-0'>
                <h3 className='my-2'>Get a Chatbot for your Website</h3>
            
               <Link to='/create' style={{textDecoration:'none'}}><button type="button" style={{color:'red', backgroundColor:'white'}} className="btn fw-bold mt-1  btn-lg rounded-pill px-5">Try Zema</button></Link>
                 
            </ul>
        </div>
       
       
      
    </div>

    <div className=' col-lg-8 col-12 pb-0 container d-flex justify-content-center '>
    <Link to='/privacy' style={{textDecoration:'none'}} className='text-center'><Nav.Link  href="#link" style={{color:'white'}}  className='my-3 mx-3' ><h4>Privacy Policy</h4></Nav.Link></Link>
    <Link to='/terms' style={{textDecoration:'none'}} className='text-center'><Nav.Link  href="#link" style={{color:'white'}}  className='my-3 mx-3' ><h4>Terms of Service</h4></Nav.Link></Link>
    </div>
    <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />
</div>
</div>
  )
}

export default Footer
